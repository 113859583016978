import React, { FC } from 'react'
import {
  Box,
  HStack,
  Progress,
  Step,
  StepIcon,
  StepIndicator,
  Stepper,
  StepSeparator,
  StepStatus,
  VStack
} from '@chakra-ui/react'
import { GrandText } from 'ui'
import { useSelector } from 'shared-redux'
import { selectedBokaServiceState } from '#/src/custom/widgets/CustomWidgetId/BokaService/slice'

interface IHeaderProps {}

const Header: FC<IHeaderProps> = ({}) => {
  const state = useSelector(selectedBokaServiceState)
  const max = state.steps.length - 1
  const progressPercent = (state.activeStepIndex / max) * 100

  return (
    <VStack
      mx="auto"
      spacing={16}
      w={{
        base: 'full',
        md: '2xl'
      }}>
      <Box position="relative" w="full">
        <VStack spacing={4} w="full">
          <Stepper colorScheme="teal" gap="2" index={state.activeStepIndex + 1} size="xs" w="full">
            {state.steps.map((s) => (
              <Step key={s.id}>
                <VStack>
                  <StepIndicator>
                    <StepStatus complete={<StepIcon />} />
                  </StepIndicator>
                </VStack>
                <StepSeparator />
              </Step>
            ))}
          </Stepper>
          <HStack w="full">
            {state.steps.map((s, i) => (
              <GrandText
                flex={1}
                fontSize="xs"
                key={s.id}
                textAlign={i === 0 ? 'left' : i === state.steps.length - 1 ? 'right' : 'center'}>
                {s.label}
              </GrandText>
            ))}
          </HStack>
        </VStack>
        <Progress
          height="3px"
          position="absolute"
          top="10px"
          value={progressPercent}
          width="full"
          zIndex={-1}
        />
      </Box>
    </VStack>
  )
}

export default Header
