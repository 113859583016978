'use client'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import type { BoxProps, FlexProps } from '@chakra-ui/react'
import { Box, Flex } from '@chakra-ui/react'
import { isMobileOnly } from 'react-device-detect'
import {
  AspectRatio,
  ContentContainer,
  GrandDivider,
  GrandHeading,
  GrandNextLink,
  GrandRibbon,
  GrandText,
  ResponsiveTextBox
} from 'ui'
import type { HeroWidgetType, StorefrontWidget } from 'ecosystem'
import type { DynamicHeroImageProps } from 'ui/common/DynamicHeroImage'
import DynamicHeroImage from 'ui/common/DynamicHeroImage'
import { WidgetImplementation } from 'storefront-modules/widgets'

type HeroNoAspectRatioProps = WidgetImplementation<StorefrontWidget<HeroWidgetType>>

type WrapperProps = BoxProps & {
  type: 'RATIO' | 'NORATIO'
  children: any
}

interface ImageHeroProps extends DynamicHeroImageProps {
  type: 'RATIO' | 'NORATIO'
}

const ImageHero: FC<ImageHeroProps> = ({ type, ...props }) => {
  if (type === 'RATIO') {
    return <DynamicHeroImage {...props} />
  }
  return (
    <Box>
      <DynamicHeroImage objectFit="cover" {...props} />
    </Box>
  )
}

const Wrapper: FC<WrapperProps> = ({ type, children, ...props }) => {
  if (type === 'RATIO') {
    return (
      <AspectRatio<FlexProps, 'w'>
        breakpoints={{
          base: '4:3',
          sm: '16:9'
        }}
        {...props}>
        {children}
      </AspectRatio>
    )
  }
  return (
    <Flex h={{ base: '75vw', md: 'auto' }} pb="30%" position="relative" w="full" {...props}>
      {children}
    </Flex>
  )
}

const Hero: FC<HeroNoAspectRatioProps> = ({
  widgetId,
  widget: {
    widget: {
      alt,
      mobileImgUrl,
      imgUrl,
      withAspectRatio,
      mobileLayout,
      title,
      ribbonText,
      slogan,
      sub,
      cta,
      headingTag
    }
  }
}) => {
  const [deviceImgUrl, setDeviceImgUrl] = useState<string | null>(null)

  useEffect(() => {
    if (isMobileOnly && mobileImgUrl) {
      setDeviceImgUrl(mobileImgUrl)
    } else {
      setDeviceImgUrl(imgUrl)
    }
  }, [imgUrl, mobileImgUrl])

  const type = withAspectRatio ? 'RATIO' : 'NORATIO'

  return (
    <Box id={widgetId}>
      <Flex
        className="hero__wrapper"
        direction="column"
        display={{
          base: mobileLayout === 'COMPACT' ? 'flex' : 'none',
          lg: 'none'
        }}
        w="full">
        <GrandHeading
          className="hero__title"
          fontSize="2xl"
          headingTag="h1"
          textAlign="center"
          title={title || ''}
        />
        <GrandDivider />
      </Flex>
      <Wrapper
        className="hero__wrapper"
        color="text.light"
        display={{
          base: mobileLayout === 'COMPACT' ? 'none' : 'flex',
          lg: 'flex'
        }}
        mt={ribbonText ? 5 : 0}
        {...{ type }}>
        {ribbonText ? <GrandRibbon ribbonText={ribbonText} /> : null}

        <ContentContainer
          alignItems="center"
          bottom={0}
          className="hero__content-container"
          direction="column"
          h="100%"
          justifyContent="center"
          left={0}
          position="absolute"
          px={{
            base: 4,
            md: 6
          }}
          right={0}
          spacing={4}
          textAlign="center"
          zIndex={2}>
          {slogan ? (
            <GrandText className="hero__slogan" fontSize={['md', 'lg']} textStyle="slogan">
              {slogan}
            </GrandText>
          ) : null}

          {title ? (
            <ResponsiveTextBox
              as={headingTag ? headingTag : 'h1'}
              className="hero__title"
              color="text.light"
              fontFamily="heading"
              fontSize={['4xl', '5xl']}
              fontWeight="medium"
              w="100%">
              {title}
            </ResponsiveTextBox>
          ) : null}

          {title || sub ? (
            <GrandDivider
              borderColor="text.light"
              borderWidth={1}
              className="hero__divider"
              opacity={1}
              w="40%"
            />
          ) : null}

          {sub ? (
            <GrandText className="hero__sub" fontSize={['lg', 'xl']}>
              {sub}
            </GrandText>
          ) : null}

          {cta ? (
            <GrandNextLink
              aria-label={cta.label}
              className="hero__cta"
              href={cta.path}
              isButton
              isExternal={cta.isExternal}>
              {cta.label}
            </GrandNextLink>
          ) : null}
        </ContentContainer>

        {deviceImgUrl ? (
          <>
            <Box
              bg="rgba(0,0,0,0.25)"
              className="hero__overlay"
              h="100%"
              position="absolute"
              w="100%"
              zIndex={1}
            />
            <Box>
              <ImageHero alt={alt ? alt : 'hero'} priority src={deviceImgUrl} {...{ type }} />
            </Box>
          </>
        ) : null}
      </Wrapper>
    </Box>
  )
}

export default Hero
