import React, { FC, useCallback, useEffect, useMemo } from 'react'
import { Flex, VStack } from '@chakra-ui/react'

import CheckboxOptions from '../CheckboxOptions'
import { nextStep, selectedBokaServiceState, updateAttachments, updateServices } from '../slice'

import StepWrapper from './StepWrapper'
import ProductStepForm from './ProductStepForm'
import { StepNavButton } from './StepNavButton'
import { AttachmentsInput } from './AttachmentsInput'
import { useCheckboxes } from 'ui/hooks'
import { useDispatch, useSelector } from 'shared-redux'

interface ProductStepProps {}

const ProductStep: FC<ProductStepProps> = () => {
  const { services, attachments, productForm } = useSelector(selectedBokaServiceState)
  const { selected, handleStringCheckBoxes } = useCheckboxes(services)
  const dispatch = useDispatch()

  const setAttachments = useCallback(
    (newAttachments: File[]) => {
      dispatch(updateAttachments(newAttachments))
    },
    [dispatch]
  )

  useEffect(() => {
    dispatch(updateServices(selected))
  }, [dispatch, selected])

  const handleOnServiceSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleStringCheckBoxes(event)
  }

  const handleNext = () => {
    dispatch(nextStep())
  }

  const isNextAvailable = useMemo(() => {
    return (
      !!selected.length &&
      !!productForm?.product &&
      !!productForm?.brand &&
      !!productForm?.serviceDate
    )
  }, [productForm, selected])

  const servicesOptions = useMemo(
    () => [
      { label: 'Service', id: 'service' },
      { label: 'Installation', id: 'installation' },
      { label: 'Underhållsservice', id: 'underhallsservice' },
      { label: 'IFB', id: 'ifb' },
      { label: 'Annat', id: 'annat' }
    ],
    []
  )

  return (
    <StepWrapper title="Boka service direkt">
      <VStack spacing={8} w="full">
        <CheckboxOptions
          label="Vad behöver du hjälpa med?"
          onSelect={handleOnServiceSelect}
          options={servicesOptions}
          selected={selected}
        />

        <Flex flexDirection="column" gap={4} w="100%">
          <ProductStepForm />

          <AttachmentsInput attachments={attachments} onChange={setAttachments} />
        </Flex>

        <Flex justifyContent="flex-end" w="full">
          <StepNavButton
            className="book_service_snd-2"
            id="book_service_snd"
            isDisabled={!isNextAvailable}
            onClick={handleNext}>
            Nästa steg
          </StepNavButton>
        </Flex>
      </VStack>
    </StepWrapper>
  )
}

export default ProductStep
