'use client'
import type { FC } from 'react'
import { GrandHeading, GrandNextLink, GrandSection, HTMLArticle } from 'ui'
import { Flex, VStack } from '@chakra-ui/react'
import type { StorefrontWidget, WidgetCTAWidgetType } from 'ecosystem'
import { WidgetImplementation } from 'storefront-modules/widgets'
import { createCn } from 'shared-utils'

type CustomWidgetCTAProps = WidgetImplementation<StorefrontWidget<WidgetCTAWidgetType>>

const cn = createCn('widget-cta')

const CTA_WIDGET_CN = {
  SECTION: 'section',
  SECTION_CONTAINER: 'section_container',
  LINK_WRAPPER: 'link_wrapper',
  TITLE: 'heading',
  CONTENT: 'content'
}

const CustomWidgetCTA: FC<CustomWidgetCTAProps> = ({
  widgetId,
  widget: {
    widget: { text, headingTag, btnLabel, path, isExternal, sub }
  }
}) => {
  return (
    <GrandSection
      className={cn(CTA_WIDGET_CN.SECTION)}
      contentContainerProps={{
        className: cn(CTA_WIDGET_CN.SECTION_CONTAINER),
        direction: {
          base: 'column',
          md: 'row'
        },
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
      py={0}
      sectionId={widgetId}>
      <VStack
        alignItems={{
          base: 'center',
          md: 'stretch'
        }}>
        {text ? (
          <GrandHeading
            className={cn(CTA_WIDGET_CN.TITLE)}
            fontSize="2xl"
            headingTag={headingTag}
            textAlign={{
              base: 'center',
              md: 'initial'
            }}
            title={text}
          />
        ) : null}

        {sub ? (
          <HTMLArticle
            className={cn(CTA_WIDGET_CN.CONTENT)}
            content={sub}
            textAlign={{
              base: 'center',
              md: 'initial'
            }}
          />
        ) : null}
      </VStack>

      <Flex alignItems="center" className={cn(CTA_WIDGET_CN.LINK_WRAPPER)}>
        <GrandNextLink
          aria-label={btnLabel}
          href={path}
          isButton
          isExternal={isExternal}
          minW="220px">
          {btnLabel}
        </GrandNextLink>
      </Flex>
    </GrandSection>
  )
}

export default CustomWidgetCTA
