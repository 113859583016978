import React, { FC, useEffect, useMemo } from 'react'
import { Stack, VStack } from '@chakra-ui/react'
import lightDateFnsFormat from 'date-fns/lightFormat'
import { useCheckboxes } from 'ui/hooks'

import CheckboxOptions from '../CheckboxOptions'
import { nextStep, prevStep, selectedBokaServiceState, updateCommunication } from '../slice'

import StepWrapper from './StepWrapper'
import ContactDetailsStepForm from './ContactDetailsStepForm'
import { StepNavButton } from './StepNavButton'
import useGenericRequest from 'api/utils/next/useGenericRequest'
import { StoreFrontPostContactReqFields } from 'storefront-modules/contact/api'
import { useDispatch, useSelector } from 'shared-redux'

interface ContactDetailsStepProps {}

const ContactDetailsStep: FC<ContactDetailsStepProps> = () => {
  const { contactForm, productForm, services, communication, attachments } =
    useSelector(selectedBokaServiceState)
  const communicationOptions = [{ label: 'Epost', id: 'email' }]
  const { selected, handleStringCheckBoxes } = useCheckboxes(communication)
  const { fetchNextRoute, error } = useGenericRequest()
  const dispatch = useDispatch()

  const handleOnCommunicationSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleStringCheckBoxes(event)
  }

  useEffect(() => {
    dispatch(updateCommunication(selected))
  }, [dispatch, selected])

  const handleNext = () => {
    dispatch(nextStep())
    handleOnSubmit()
  }

  const handlePrev = () => {
    dispatch(prevStep())
  }

  const handleOnSubmit = () => {
    const payload = {
      name: contactForm.name,
      phone: `Telefonnummer: ${contactForm.phone} | Adress: ${contactForm.address} | Postnummer: ${contactForm.postalCode} | Ort: ${contactForm.locality}`,
      email: contactForm.email,
      product: `Produkt: ${productForm.product} | Varumärke: ${productForm.brand} | Service: ${services}`,
      description: productForm.description,
      orderNumber: productForm.serviceDate
        ? lightDateFnsFormat(new Date(productForm.serviceDate), 'yyyy-MM-dd')
        : ''
    } as StoreFrontPostContactReqFields
    const formData = new FormData()
    formData.append('contact', JSON.stringify(payload))

    if (attachments.length) {
      attachments?.forEach?.((attachment, index) => {
        formData.append('file' + index, attachment, attachment?.name)
      })
    }

    fetchNextRoute(`/api/contact/send-contact`, {
      method: 'POST',
      body: formData
    })
  }

  const isNextAvailable = useMemo(() => {
    return (
      !!selected.length &&
      !!contactForm?.phone &&
      !!contactForm?.email &&
      !!contactForm?.address &&
      !!contactForm?.postalCode &&
      !!contactForm?.locality
    )
  }, [
    selected.length,
    contactForm?.phone,
    contactForm?.email,
    contactForm?.address,
    contactForm?.postalCode,
    contactForm?.locality
  ])

  return (
    <StepWrapper title="Boka service direkt">
      <VStack spacing={8} w="full">
        <CheckboxOptions
          label="Skicka bekräftelse som: "
          onSelect={handleOnCommunicationSelect}
          options={communicationOptions}
          selected={selected}
        />

        <ContactDetailsStepForm error={error?.message} />

        <Stack
          direction={{
            base: 'column',
            md: 'row'
          }}
          gap={4}
          justifyContent="space-between"
          w="full">
          <StepNavButton bg="brand.blue" borderColor="brand.blue" onClick={handlePrev}>
            Föregående steg
          </StepNavButton>

          <StepNavButton
            className="book_service_snd-1"
            isDisabled={!isNextAvailable}
            onClick={handleNext}>
            Nästa steg
          </StepNavButton>
        </Stack>
      </VStack>
    </StepWrapper>
  )
}

export default ContactDetailsStep
