'use state'
import React, { FC } from 'react'
import { VStack } from '@chakra-ui/react'
import { Link } from '@chakra-ui/next-js'
import { GrandText } from 'ui'

export interface IContactTextProps {}

const ContactText: FC<IContactTextProps> = () => {
  return (
    <VStack pb={{ base: 10, lg: 0 }} pr={{ base: 0, lg: 20 }} w={{ base: '100%', lg: '50%' }}>
      <GrandText fontSize={32} mb={5}>
        Kontakta oss
      </GrandText>
      <GrandText>
        Vi är väldigt stolta över att vi har mer än 40 år i branschen och massor av nöjda kunder med
        lyckade projekt i bagaget, stora som små. Därför erbjuder vi kostnadsfri rådgivning och
        hoppas att det blir starten på ett långt och givande samarbete!
      </GrandText>

      <GrandText>
        <br />
        <b>Stockholm - Mälardalen:</b>
        <br />
        08-83 80 90
        <br />
        <br />
        <b>Malmö - Skåne:</b>
        <br />
        040-643 20 15
        <br />
        <br />
        <Link display="flex" href="mailto:info@stenlundsprofessional.se">
          <GrandText as="u" cursor="pointer" fontSize="md">
            info@stenlundsprofessional.se
          </GrandText>
        </Link>
      </GrandText>
    </VStack>
  )
}

export default ContactText
