import { StackProps, Text, VStack } from '@chakra-ui/react'
import { GrandHeading, GrandLink, GrandLinkProps } from 'ui'

const ContactSupportLink = (props: GrandLinkProps) => (
  <GrandLink
    {...props}
    _hover={{
      textDecoration: 'none'
    }}
    textDecoration="underline"
  />
)

export const ContactSupport = (props: StackProps) => {
  return (
    <VStack
      alignItems="flex-start"
      fontSize={{
        base: 'sm',
        md: 'sm'
      }}
      spacing={4}
      {...props}>
      <GrandHeading
        containerProps={{
          m: 0
        }}
        fontSize={{
          base: '2xl',
          md: '4xl'
        }}
        title="Kontakta kundtjänst"
      />

      <VStack alignItems="flex-start">
        <Text>Måndag – Fredag: 08:00–16:30</Text>
        <Text>Lunch stängt: 12:00-12:30</Text>
        <Text>Lördag – Söndag: Stängt</Text>
      </VStack>

      <VStack
        alignItems="flex-start"
        fontSize={{
          base: 'md',
          md: 'lg'
        }}
        fontWeight="600"
        spacing={4}>
        <Text>
          {`Telefon: `}

          <ContactSupportLink href="tel:0842003290">08 - 4200 32 90</ContactSupportLink>
        </Text>

        <Text>
          {`Epost: `}

          <ContactSupportLink href="mailto:info@stenlundsprofessional.se">
            info@stenlundsprofessional.se
          </ContactSupportLink>
        </Text>
      </VStack>
    </VStack>
  )
}
