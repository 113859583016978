import React, { FC, useCallback, useMemo } from 'react'
import { DatePickerComponent, FormWrapper } from 'react-formatge'
import { FormFieldType, FormUpdatePayload } from 'react-formatge/dist/esm/types'
import { CalendarProps } from 'react-date-range'
import svLocale from 'date-fns/locale/sv'

import { ProductFormFields } from '../types'

import { commonStepFormStyles } from './constants'
import { StepFormSelect, StepFormSelectOption } from './StepFormSelect'
import { useGetBrands } from 'storefront-modules/brands'
import { isDateWeekend } from 'shared-utils'
import {
  selectedBokaServiceState,
  updateProductForm
} from '#/src/custom/widgets/CustomWidgetId/BokaService/slice'
import { useDispatch, useSelector } from 'shared-redux'

interface ProductStepFormProps {}

const ProductStepForm: FC<ProductStepFormProps> = () => {
  const { data: brands, isLoading } = useGetBrands('/api/get-brands')
  const dispatch = useDispatch()
  const { productForm } = useSelector(selectedBokaServiceState)

  const brandOptions = useMemo<StepFormSelectOption[]>(
    () =>
      (brands || []).map(({ name }) => ({
        label: name,
        value: name
      })),
    [brands]
  )

  const inputFields: FormFieldType<ProductFormFields>[] = [
    {
      name: 'brand',
      componentType: 'component',
      label: 'Välj varumärke',
      initialValue: productForm.brand,
      validation: {
        required: true
      },
      component: (
        <StepFormSelect
          // https://github.com/JedWatson/react-select/issues/5459
          instanceId="boka-service-product-form-brand-select"
          isClearable
          isCreatable
          isLoading={isLoading}
          options={brandOptions}
        />
      )
    },
    {
      name: 'serviceDate',
      componentType: 'component',
      label: 'Önskat servicedatum',
      initialValue: productForm.serviceDate ? new Date(productForm.serviceDate) : null,
      component: (
        <DatePickerComponent
          datePickerProps={
            {
              locale: svLocale,
              weekStartsOn: 1,
              minDate: new Date(),
              disabledDay: (date) => {
                return isDateWeekend(date)
              }
            } as CalendarProps
          }
          title="Önskat servicedatum"
        />
      ),
      validation: {
        required: true
      }
    },
    {
      name: 'product',
      componentType: 'input',
      label: 'Ange artikelnummer / modell / namn',
      initialValue: productForm.product,
      textarea: true,
      validation: {
        required: true
      }
    },
    {
      name: 'description',
      componentType: 'input',
      label: 'Beskriv ditt ärende',
      initialValue: productForm.description,
      textarea: true,
      validation: {
        required: false
      }
    }
  ]

  const buttonProps = {
    display: 'none'
  }

  const handleOnUpdateData = useCallback(
    ({ updatedData }: FormUpdatePayload<ProductFormFields>) => {
      if (!Object.values(updatedData).length) {
        return
      }

      dispatch(
        updateProductForm({
          product: updatedData.product ?? productForm.product,
          brand: updatedData.brand ?? productForm.brand,
          description: updatedData.description ?? productForm.description,
          // @ts-ignore -- Fix types
          serviceDate:
            typeof updatedData.serviceDate === 'undefined'
              ? productForm.serviceDate
              : updatedData.serviceDate?.toDateString() ?? null
        })
      )
    },
    [
      dispatch,
      productForm.brand,
      productForm.description,
      productForm.product,
      productForm.serviceDate
    ]
  )

  return (
    <FormWrapper<ProductFormFields>
      {...commonStepFormStyles}
      onUpdate={handleOnUpdateData}
      {...{ inputFields, buttonProps }}
    />
  )
}

export default React.memo(ProductStepForm)
